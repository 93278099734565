$(document).foundation();

var vimeo = '<iframe src="https://player.vimeo.com/video/VIMEO_ID?color=FFF7C9" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
var link = '<a href="LINK_URL" target="_blank">LINK_TEXT</a>';
var image = '<div class="sm-detail-image"><img src="IMAGE_URL"></div>';
var contact = '<p>&#104;&#101;&#108;&#108;&#111;<code>&#64;</code>&#115;&#97;&#110;&#100;&#111;&#114;&#109;&#111;&#108;&#100;&#97;&#110;<code>&#46;</code>&#99;&#111;&#109;</p><p class="no-wrap"><code>&#43;</code>&#54;&#49;&#32;<code>&#40;</code>&#48;<code>&#41;</code>&#32;&#52;&#49;&#49;&#32;&#52;&#50;&#49;&#32;&#50;&#54;&#53;</p><p><a href="&#104;&#116;&#116;&#112;&#58;&#47;&#47;&#108;&#105;&#110;&#107;&#101;&#100;&#105;&#110;&#46;&#99;&#111;&#109;&#47;&#105;&#110;&#47;&#115;&#97;&#110;&#100;&#111;&#114;&#109;&#111;&#108;&#100;&#97;&#110;" target="_blank">&#104;&#116;&#116;&#112;<code>&#58;&#47;&#47;</code>&#108;&#105;&#110;&#107;&#101;&#100;&#105;&#110;<code>&#46;</code>&#99;&#111;&#109;<code>&#47;</code>&#105;&#110;<code>&#47;</code>&#115;&#97;&#110;&#100;&#111;&#114;&#109;&#111;&#108;&#100;&#97;&#110;</a></p><p><a href="&#104;&#116;&#116;&#112;&#115;&#58;&#47;&#47;&#97;&#110;&#103;&#101;&#108;&#46;&#99;&#111;&#47;&#115;&#97;&#110;&#100;&#111;&#114;&#109;&#111;&#108;&#100;&#97;&#110;" target="_blank">&#104;&#116;&#116;&#112;&#115;<code>&#58;&#47;&#47;</code>&#97;&#110;&#103;&#101;&#108;<code>&#46;</code>&#99;&#111;<code>&#47;</code>&#115;&#97;&#110;&#100;&#111;&#114;&#109;&#111;&#108;&#100;&#97;&#110;</a></p>';

$(document).ready(function() {
	$("#sm-data-inject").html(contact);
	OnScroll();
	$(window).scroll(function() { 
		OnScroll();
	});
	$(document).on('keydown', function(event) {
		if (event.key == "Escape" && showingContent) {
			HideContent();
		}
	});
});

var showingContent = false;
var hasVimeo = false;
var hasSlick = false;
var lastScrollTop = 0;

function ShowContent(group, id) {
	showingContent = true;
	$("#sm-detail-modal").css("display", "inline-block");
	lastScrollTop = $(window).scrollTop();
	$(window).scrollTop(0);
	var h = $("#sm-detail-modal").height();
	$("#sm-content").css("max-height", h);
	GetContent(group, id);
}

function HideContent() {
	showingContent = false;
	$("#sm-detail-modal").css("display", "none");
	$("#sm-content").css("max-height", "unset");
	$(window).scrollTop(lastScrollTop);
	DestroyContent();
}

function GetContent(group, id) {
	var data;
	switch (group) {
		case "work":
		data = content.work;
		break;
		case "play":
		data = content.play;
		break;
	}
	var project;
	var n = data.length;
	for (var i = 0; i < n; i++) {
		if (data[i].id == id) {
			project = data[i];
			break;
		}
	}
	if (project) {
		hasVimeo = project.vimeo != null;
		hasSlick = project.images != null;
		$("#sm-detail-carousel").css("display", (project.type == "image" && project.images != null) ? "block" : "none");
		if (project.type == "image" && project.images != null) {
			var images = "";
			var n = project.images.length;
			for (var i = 0; i < n; i++) {
				images += image.replace("IMAGE_URL", project.images[i].url);
			}
			$("#sm-detail-carousel").html(images);
			$('#sm-detail-carousel').slick({
				centerMode: true,
				slidesToShow: 2,
				dots: true,
				arrows: false,
				responsive: [
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 1
						}
					}
				]
			});
		}
		$("#sm-detail-vimeo").css("display", project.vimeo ? "block" : "none");
		$("#sm-detail-vimeo").html(project.vimeo ? vimeo.replace("VIMEO_ID", project.vimeo) : null);
		$("#sm-detail-link").css("display", project.link ? "block" : "none");
		$("#sm-detail-link").html(project.link ? link.replace("LINK_URL", project.link.url).replace("LINK_TEXT", project.link.text) : null);
		$("#sm-detail-title").html(project.title);
		$("#sm-detail-project").css("display", project.project ? "block" : "none");
		$("#sm-detail-project").html(project.project ? "<strong>Project:</strong> "+project.project : null);
		$("#sm-detail-agency").css("display", project.agency ? "block" : "none");
		$("#sm-detail-agency").html(project.agency ? "<strong>"+project.agency.label+":</strong> "+project.agency.name : null);
		$("#sm-detail-role").html("<strong>Role:</strong> "+project.role);
		$("#sm-detail-description").html(project.description);
	}
}

function DestroyContent() {
	if (hasVimeo) {
		$("#sm-detail-vimeo").html(null);
	}
	if (hasSlick) {
		$("#sm-detail-carousel").css("display", "none");
		$('#sm-detail-carousel').slick('unslick');
		$("#sm-detail-carousel").html(null);
	}
}

function OnScroll() {
	var ww = $(window).width();
	if (ww <= 640) {
		var wh = $(window).height();
		$(".sm-project").each(function(index) {
			var et = $(this).offset().top;
			var ey = et - $(window).scrollTop();
			if (ey > wh * 0.05 && ey + ww < wh * 0.95) {
				if (!$(this).hasClass("highlighted")) {
					$(this).addClass("highlighted");
				}
			} else {
				if ($(this).hasClass("highlighted")) {
					$(this).removeClass("highlighted");
				}
			}
		});
	}
}